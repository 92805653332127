export default class Navbar {
    constructor() {
        Navbar.sticky()
        Navbar.burger()
        Navbar.langsSwitcher()
        Navbar.messenger()
    }

    static sticky() {
        let last_scroll_top = 0

        $(window).on('load scroll', function () {
            const scroll_top = $(this).scrollTop()
            const $navbar = $('[data-id="wpiris-navbar"]')

            if (scroll_top >= last_scroll_top && scroll_top > $navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            last_scroll_top = scroll_top

            if (scroll_top > 50) {
                $navbar.addClass('navbar--onscroll')
            } else {
                $navbar.removeClass('navbar--onscroll')
            }
        })
    }

    static burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        $(trigger).on('click', () => {
            $('body').toggleClass('menu-open')
        })

        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })
    }

    static langsSwitcher() {
        const current_lang = $('.tools__item__languages__current')
        const list_langs = $('.tools__item__languages__langs')

        //On init
        $(window).on('load', () => {
            window.setTimeout(() => {
                let current_language = $('html').attr('lang').replace(/-.*/gi, '')
                if (current_language === 'auto') {
                    current_language = 'fr'
                }

                $('.tools__item__languages__langs > a').each(function () {
                    if ($(this).text().toUpperCase() === current_language.toUpperCase()) {
                        $(this).hide()
                    }
                })

                $(current_lang).text(current_language)
            }, 500)
        })

        //Open submenu
        $(current_lang).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
        })

        //Select lang
        $(list_langs).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
        })

        //Change Navbar Language on click
        $('.tools__item__languages__langs > a').on('click', function () {
            $('.tools__item__languages__langs > a').show()
            $(this).hide()
            $(current_lang).text($(this).text())
        })
    }

    static messenger() {
        const messenger = $('#fb-root')

        //on scroll
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 250) {
                messenger.addClass('messenger--show')
            } else {
                messenger.removeClass('messenger--show')
            }
        })
    }
}
